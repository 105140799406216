import Player from "@vimeo/player"
import { addPropertyControls, ControlType, RenderTarget } from "framer"
import { useCallback, useEffect, useMemo, useState, useRef } from "react"
import { motion } from "framer-motion"

import {
    useRadius,
    borderRadiusControl,
} from "https://framer.com/m/framer/default-utils.js@^0.45.0"

const vimeoRegex = /^https?:\/\/vimeo\.com\/(\d+)/

/**
 * VIMEO PLAYER
 * MADE BY LENDERSON MACEDO
 * @lenderson.macedo
 *
 * @framerIntrinsicWidth 375
 * @framerIntrinsicHeight 210
 * @framerDisableUnlink
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export default function VimeoPlayer(props) {
    const {
        videoUrl,
        autoplay,
        playOnCanvas,
        mute,
        controls,
        loop,
        titles,
        eventTrigger: { startLesson, completeLesson },
        colors: { playerColor, backgroundColor },
        onLessonStart = () => {},
        onLessonComplete = () => {},
        onPlay = () => {},
        onPause = () => {},
        onEnd = () => {},
        style,
    } = props

    const borderRadius = useRadius(props)

    const playerRef = useRef(null)
    const startEventFired = useRef(false)
    const completeEventFired = useRef(false)

    const checkMarkLessonStart = (seconds) => {
        if (!startEventFired.current && seconds >= startLesson) {
            onLessonStart()
            startEventFired.current = true
        }
    }

    const checkMarkLessonComplete = async (duration, seconds) => {
        const timeRemaining = duration - seconds

        if (!completeEventFired.current && timeRemaining <= completeLesson) {
            onLessonComplete()
            completeEventFired.current = true
        }
    }

    useEffect(() => {
        const vimeoMatch = videoUrl.match(vimeoRegex)
        const id = vimeoMatch ? vimeoMatch[1] : 0

        const player = new Player(playerRef.current, {
            id,
            autopause: false,
            autoplay:
                RenderTarget.current() === RenderTarget.canvas
                    ? playOnCanvas && autoplay
                    : autoplay,
            byline: titles,
            controls: controls,
            loop: loop,
            title: titles,
            portrait: titles,
            muted: mute,
            responsive: true,
        })

        player.setColor(playerColor)

        const onTimeUpdate = ({ seconds, duration }) => {
            checkMarkLessonStart(seconds)
            checkMarkLessonComplete(duration, seconds)
        }

        player.on("timeupdate", onTimeUpdate)
        player.on("play", onPlay)
        player.on("pause", onPause)
        player.on("ended", onEnd)

        return () => {
            player.off("timeupdate", onTimeUpdate)
            player.off("play", onPlay)
            player.off("pause", onPause)
            player.off("ended", onEnd)
            player.destroy()
        }
    }, [
        videoUrl,
        playerRef,
        playOnCanvas,
        autoplay,
        controls,
        loop,
        mute,
        titles,
    ])

    return (
        <motion.div
            style={{
                background: backgroundColor,
                ...style,
                position: "relative",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                borderRadius,
            }}
        >
            <div
                className="framer-vimeo"
                ref={playerRef}
                style={{
                    width: "100%",
                    height: "100%",
                }}
            />
        </motion.div>
    )
}

VimeoPlayer.defaultProps = {
    videoUrl: "https://vimeo.com/642263700",
    autoplay: false,
    playOnCanvas: false,
    loop: false,
    mute: false,
    titles: false,
}

addPropertyControls(VimeoPlayer, {
    videoUrl: {
        title: "URL do vimeo",
        type: ControlType.String,
    },
    autoplay: {
        title: "Autoplay",
        type: ControlType.Boolean,
        enabledTitle: "Play",
        disabledTitle: "Pause",
    },
    playOnCanvas: {
        title: "On Canvas",
        type: ControlType.Boolean,
        enabledTitle: "Play",
        disabledTitle: "Pause",
    },
    loop: {
        title: "Loop",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    mute: {
        title: "Mute",
        type: ControlType.Boolean,
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    controls: {
        title: "Controls",
        type: ControlType.Boolean,
        enabledTitle: "Show",
        disabledTitle: "Hide",
    },
    titles: {
        title: "Titles",
        type: ControlType.Boolean,
        enabledTitle: "Show",
        disabledTitle: "Hide",
        hidden: (props) => props.controls,
    },
    eventTrigger: {
        type: ControlType.Object,
        description: "Set Time to trigger events",
        controls: {
            startLesson: {
                title: "onStart",
                type: ControlType.Number,
                min: 0,
                max: 100,
                defaultValue: 15,
                displayStepper: true,
                unit: "seg",
            },
            completeLesson: {
                title: "onComplete",
                type: ControlType.Number,
                min: 0,
                max: 100,
                defaultValue: 15,
                displayStepper: true,
                unit: "seg",
            },
        },
    },
    colors: {
        type: ControlType.Object,
        controls: {
            playerColor: {
                type: ControlType.Color,
                defaultValue: "#FFC700",
            },
            backgroundColor: {
                type: ControlType.Color,
                title: "Background",
                defaultValue: "rgba(0, 0, 0, 0,0)",
            },
        },
    },
    ...borderRadiusControl,
    onLessonStart: {
        type: ControlType.EventHandler,
    },
    onLessonComplete: {
        type: ControlType.EventHandler,
    },
    onPlay: {
        type: ControlType.EventHandler,
    },
    onPause: {
        type: ControlType.EventHandler,
    },
    onEnd: {
        type: ControlType.EventHandler,
    },
})
